.Tourism-Container{
    overflow: hidden;
}

.map-container {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    text-align: center;
}

.map-image {
    width: 80%;
    max-width: 100%;
    height: auto;
    border: 5px solid #000;
}

.Tourism{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.Places{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
    .Places{
        flex-wrap: wrap;
    }
    .map-image {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 480px) {
    .Places{
        text-align: center;
        flex-wrap: wrap;
    }
    .map-image {
        max-width: 480px;
    }
}