.footer {
    background-color: #283055;
    padding: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: white;
}

.social-media-links {
    display: flex;
}

.social-media-links a {
    display: inline-block;
    margin-right: 10px;
    font-size: large;
    color: white;
}

.social-media-links a:hover {
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}

.footer-bottom p {
    margin: 0;
}