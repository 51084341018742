.photogallery{
    overflow: hidden;
}

.Gallery{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.Photo-head {
    text-align: center;
    font-weight: bolder;
    margin-top: 20px;
    background-color: rgb(240, 246, 246);
}

.top{
    background-image: url('../../../public/Photos/bg3-logo.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 30vh;
    width: 100%;
}

#Tikona-foot{
    text-align: center;
    margin-top: 8px;
    color: green;
}

#head2{
    margin-top: 0px;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .top {
        background-size: contain;
        height: 60vh;
    }
}