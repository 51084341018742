.navbar {
    background-color: #fff;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 50px;
}

.navbar-logo {
    justify-self: start;
    cursor: pointer;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
}

.nav-item {
    height: 80px;
    text-align: center;
}

#Nav-logo {
    width: 150px;
    height: 50px;
}

.nav-links {
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
}

.nav-links:hover {
    border-bottom: 3px solid #379237;
}

.nav-links.active {
    border-bottom: 3px solid #379237;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .navbar-container {
        padding: 0 30px;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background-color: #fff;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &:hover {
            background-color: #e4e4e4;
            border-radius: 0;
        }
    }

    .nav-links:hover {
        border-bottom: none;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
    }
}

@media screen and (max-width: 960px) {
    .navbar {
        height: 100%;
    }

    .navbar-container {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    }

    .navbar-logo {
        margin-left: 0;
        font-size: 1.8rem;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 20px;
        transform: translate(-100%, 50%);
        font-size: 1.5rem;
        cursor: pointer;
        color: #000;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 60px;
        margin-top: 20px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        background-color: #fff;
    }

    .nav-menu.active {
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;

        &:hover {
            background-color: #e4e4e4;
            border-radius: 0;
        }
    }

    .nav-links:hover {
        border-bottom: none;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
    }
}

.social-links {
    display: none;
}

.social-links.active {
    display: flex;
    justify-content: center;
}

.social-links a {
    font-size: 24px;
    margin: 0 10px;
}