.Packages-head{
    background-image: url('../../../public/Photos/bg5-logo.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 30vh;
    width: 100%;
}

.card{
    margin-top: 20px;
    font-weight: bold;
}

.Refund{
    text-align: justify;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px;
}

#Refund1 p{
    padding: 15px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    text-align: center;
}

#Refund1 p:hover{
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    transition: 1s;
}

#time{
    padding: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    color: #E87203;
    font-weight: bolder;
    
}

/* Table Style*/
.table-fill {
    background: white;
    border-radius:3px;
    border-collapse: collapse;
    height: 150px;
    margin: auto;
    max-width: 600px;
    padding:5px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    animation: float 5s infinite;
}
table{
    width: 100%;
}
th {
    color:#D5DDE5;;
    background:#283055;
    border-bottom:4px solid #9ea7af;
    border-right: 1px solid #343a45;
    font-size:23px;
    font-weight: 100;
    padding:24px;
    text-align:left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align:middle;
    text-align: center;
}

th:first-child {
    border-top-left-radius:3px;
}

th:last-child {
    border-top-right-radius:3px;
    border-right:none;
}

tr {
    border-top: 1px solid #C1C3D1;
    border-bottom: 1px solid #C1C3D1;
    color:#666B85;
    font-size:16px;
    font-weight:normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}

tr:hover td {
    background:#E87203;
    color:#FFFFFF;
    border-top: 1px solid #22262e;
}

tr:first-child {
    border-top:none;
}

tr:last-child {
    border-bottom:none;
}

tr:nth-child(odd) td {
    background:#EBEBEB;
}

tr:nth-child(odd):hover td {
    background:#E87203;
}

tr:last-child td:first-child {
    border-bottom-left-radius:3px;
}

tr:last-child td:last-child {
    border-bottom-right-radius:3px;
}

td {
    background:#FFFFFF;
    padding:13px;
    text-align:left;
    vertical-align:middle;
    font-weight:300;
    font-size:18px;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-right: 1px solid #C1C3D1;
    text-align: center;
}

td:last-child {
    border-right: 0px;
}

.button{
    align-items: center;
    text-align: center;
}

.custom-button {
    background-color: #283055;
    color: white;
    transition: background-color 0.3s, color 0.3s;
    border: none;
}

.custom-button:hover {
    background-color: #E87203;
    color: #283055;
}

#packageTitle{
    color: black;
}

@media (min-width: 768px) {
    .Packages-head {
        background-size: contain;
        height: 60vh;
    }
}

ul.bullet-list {
    list-style-type: none;
    text-align: left;
    padding: 0;
}

ul.bullet-list li {
    margin-bottom: 10px;
    font-size: 16px;
}

ul.bullet-list li u{
    text-decoration: underline;
    color: #283055;
    font-weight: bold;
}

.font-14{
    font-size: 14px;
}
.font-18{
    font-size: 18px !important;
}