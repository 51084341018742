.About {
  background-image: url('../../../public/Photos/About.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 30vh;
  width: 100%;
}

.aboutContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.Award{
  text-align: center;
  margin-top: 20px;
  font-family: sans-serif;
  background-color:rgb(240, 246, 246);
  padding: 20px;
}

@media screen and (max-width: 768px) {
  #head1{
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .About {
    background-size: contain;
    height: 60vh;
  }
}