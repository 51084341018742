.Facilities{
  overflow: hidden;
}

.Fac-Top {
  text-align: center;
  overflow: hidden;
}

.FacButtons{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -15px;
}

.Group{
  width: 100%;
  padding: 0 15px;
  margin-bottom: 30px;
  text-align: center;
}

@keyframes scale {
  50% {
    -webkit-transform:scale(1.2);
    -moz-transform:scale(1.2);
    -ms-transform:scale(1.2);
    -o-transform:scale(1.2);
    transform:scale(1.2);
  }
}

.Header{
  background-image: url('../../../public/Photos/bg2-logo.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 30vh;
  width: 100%;
}

.container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.imageContainer{
  flex: 1;
}

.imageContainer img{
  width: 20rem;
  height: auto;
  display: block;
}

@media (min-width: 768px) {
  .Header {
    background-size: contain;
    height: 60vh;
  }
}