.why {
    background-color: rgb(240, 246, 246);
    padding: 10px;
    margin: 20px;
    overflow: hidden;
}

.nav-item{
    margin-top: 10px;
}

.text-container{
    flex:1;
    padding-right: 20px;
}

.image-container{
    width: 600px;
}

#why-tikona-farms{
    width: 100%;
    height: auto;
    display: block;
}

.what {
    padding: 10px;
    overflow: hidden;
}

#para{
    margin-bottom: 10px;
}

/* Carousel.js CSS */
.carousel-image {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    object-fit: cover;
    display: none;
}
.carousel-image.active {
    opacity: 1;
    display: block;
}
.carousel-image.prev{
    display: block;
    position: absolute;
    left: -100%;
    opacity: 0.5;
}
.carousel-image.next{
    display: block;
    position: absolute;
    left: 100%;
    opacity: 0.5;
}

.carousel-image img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.carousel-image .image-text{
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    text-shadow: 1px 1px 1px black;
}

.prev-button {
    left: 0;
}
.next-button {
    right: 0;
}
.prev-button, .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    outline: none;
    transition: background-color 0.5s ease-in-out;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1;
}

.carousel-container{
    position: relative;
    height: 500px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul{
    list-style-position: inside;
    text-align: center;
}

/* Section.js */
.learn-more-section {
    background-color: #f2f2f2;
    padding: 40px;
}

.learn-more-section .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
}

.learn-more-section .text-content {
    flex: 1;
    text-align: left;
}

.learn-more-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.learn-more-section p {
    font-size: 16px;
    color: #888;
    margin-bottom: 20px;
}

.learn-more-section .image-content {
    flex: 1;
    text-align: right;
}

.learn-more-section .learn-more-image {
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
}

@media screen and (max-width: 768px) {
    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .text-content{
        margin-bottom: 20px;
    }
    .image-content {
        width: 100%;
        margin-bottom: 20px;
    }
    .learn-more-image {
        max-width: 300px;
    }
    .carousel-image .image-text{
        font-size: 1.5rem;
    }
}