.contact-container {
    margin-bottom: 30px;
}

.contact-head {
    text-align: center;
}

#head1{
    margin-bottom: 20px;
}

.contact-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.contact-section {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.contact-content {
    margin-bottom: 10px;
}

.contact-info-heading {
    font-weight: bold;
}

.contact-link {
    text-decoration: underline;
    color: #E87203;
}

.google-maps-container {
    width: 100%;
    height: 250px;
    overflow: hidden;
    border-radius: 5px;
}

.google-maps-iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.contact-section:hover {
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}