body {
  background-color: #f5f5f7;
  color: #25190E;
  font-family: "Helvetica-Light", Arial, Helvetica, sans-serif;
  font-display: swap;
}

/* IMAGES */
.card{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 30px;
  margin-left: 50px;
  text-align: center;
}
.card:hover{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* box-shadow: #E87203 0px 5px 10px; */
  transition: 0.2s;
  transform: scale(1.05);
}

.card-img-top{
  height: 200px;
  margin-bottom: 20px;
}

.card-title{
  text-align: center;
  font-weight: bold;
  color: #283055;
}

/* Hover CSS Animation */
#head1{
  font-weight: 700;
  font-size: 2.5rem;
  color: #255946;
  margin-bottom: 50px;
}

#head2{
  font-size: 32px;
  font-weight: 600;
  color: #283055;
  margin-bottom: 30px;
}

#head1, #head2{
  margin-top: 20px;
  font-family: "Oswald", "Roboto", "sans-serif" ;
  display: inline-block;
  position: relative;
}
#head1:after, #head2:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

#head1:after, #head2:after{
  background-color: green;
}

#head1:hover:after, #head2:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#para{
  margin-top: -20px;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
  line-height: 2.0;
  padding: 10px;
  font-size: 17px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

#para.animated {
  opacity: 1;
  transform: translateY(0);
}

/* Loading Page */
.loading-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  border: 10px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}